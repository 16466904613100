import * as React from "react";
import { ThemeProvider } from 'styled-components';
import { theme } from '../common/theme/appModern';
import { ResetCSS } from '../common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from '../containers/AppModern/Navbar';
import Banner from '../containers/AppModern/Banner/index2';
import BannerSection2 from '../containers/SassMinimal/BannerSection/index2';

import AppSlider from '../containers/AppModern/AppSlider';
import Features from '../containers/AppModern/Features';
import DashboardFeatures from '../containers/AppModern/Dashboard';
// import ProductSlide from 'containers/AppModern/ProductSlide';
import DesignedAndBuilt from '../containers/AppModern/DesignedAndBuilt';
import LocationSection from '../containers/Ride/LocationSelection';
import Feature from '../containers/Ride/Feature';
import Input from '../common/components/Input';
import Button from '../common/components/Button';
import Support from '../containers/AgencyDigital/Support';
import BEP20 from './bep20.json';
import { ethers } from 'ethers'

// import PricingPolicy from 'containers/AppModern/PricingPolicy';
// import TeamPortfolio from 'containers/AppModern/TeamPortfoilo';
// import Testimonial from 'containers/AppModern/Testimonial';
// import Newsletter from 'containers/AppModern/Newsletter';
import Footer from '../containers/AppModern/Footer';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from '../containers/AppModern/appModern.style';

import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import { Web3Provider } from "@ethersproject/providers";
import { formatEther } from "@ethersproject/units";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  injected,bscConnector
} from "../connectors";
import { useEagerConnect, useInactiveListener } from "../hooks";
import Web3 from 'web3';
import ABI from './token_bet.json';
import HEADS from '../HEADS.png';
import TAILS from '../Tails.png';
import { Spinner } from "../Spinner";
import {
    useParams
  } from "react-router";
const axios = require('axios');

const connectorsByName = {
  MetaMask: injected,
  BinanceWallet: bscConnector
};

function getErrorMessage(error) {
  if (error instanceof NoEthereumProviderError) {
    return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network.";
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorFrame
  ) {
    return "Please authorize this website to access your Ethereum account.";
  } else {
    console.error(error);
    return "An unknown error occurred. Check the console for more details.";
  }
}

function TOKENGAME () {

  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error
  } = context;
  const [variant, setVariant] = React.useState(0);
  const [bnb, setBnb] = React.useState(0);
  const [isresult, setIsresult] = React.useState(false);
  const [videos, setVideos] = React.useState('');
  let { tokenaddress } = useParams();


  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState();
  React.useEffect(() => {
    console.log('running')
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
      window.location.reload()
    }
  }, [activatingConnector, connector]);



  const [events, setEvents] = React.useState([])
  const [created, setCreated] = React.useState([])
  const [games, setGames] = React.useState([])

  const [symbol, setSymbol] = React.useState();
  const [decimals, setDecimals] = React.useState();
  const [allowed, setAllowed] = React.useState(true)
  const [tokenBalance, setTokenBalance] = React.useState(true)
  const [totalUser, setTotalUser] = React.useState(0);
  const [totalWon, setTotalWon] = React.useState(0);

  React.useEffect(async () => {
    let web3;
    if(Web3.givenProvider == null){
        web3 = new Web3('https://bsc-dataseed1.binance.org:443');
    } else {
        web3 = new Web3(Web3.givenProvider);
    }
    const contractAddress = process.env.REACT_APP_TOKEN;
    const smartContract = new web3.eth.Contract(BEP20, tokenaddress);
    const query = new URLSearchParams(window.location.search);
    const token = query.get('ref')
    if(token !== undefined){
      const validAdd = web3.utils.isAddress(token);
      if(validAdd == true){
        localStorage.setItem('referral', token);
      }
    }
    const symbol = await smartContract.methods.symbol().call();
    setSymbol(symbol);
    const decimals = await smartContract.methods.decimals().call();
    setDecimals(decimals);
    const smartContract2 = new web3.eth.Contract(ABI, contractAddress);
    // check allowance
    if(account){
        const allowance = await smartContract.methods.allowance(account,contractAddress).call();
        if(allowance == 0){
            setAllowed(false)
        }else {
            setAllowed(true);
        }
    let totalBet = await smartContract2.methods.betAmount(account,tokenaddress).call();
    let totalWon = await smartContract2.methods.winnings(account,tokenaddress).call();
    let balance = await smartContract.methods.balanceOf(account).call();
    totalBet = totalBet/10**decimals;
    totalWon = totalWon/10**decimals;
    balance = balance/10**decimals;
    setTotalUser(parseFloat(totalBet).toFixed(3));
    setTotalWon(parseFloat(totalWon).toFixed(3));
    setTokenBalance(parseFloat(balance).toFixed(3));
    }
    let events = await smartContract2.methods.getTokenGames(tokenaddress).call();
    if(events.length > 5){
        let theEvents = [];
        for(let i=events.length-1; i > events.length-5; i--){
          const isPlayed = await smartContract2.methods._games(events[i]).call();
            theEvents.push(
              <div className="row" style={{boxSizing: "border-box",borderRadius: "40px", margin:'auto', textAlign:'left', marginLeft:'1%', marginBottom:'20px'}}>
              <div className="col-6 d-none d-sm-block" style={{height:'40px',lineHeight:'40px'}}>
                {isPlayed.player1}
                </div>
                <div className="col" style={{height:'40px',lineHeight:'40px', marginLeft:'1%'}}>
                  {symbol}
                </div>
                <div className="col"  style={{height:'40px',lineHeight:'40px'}}>
                {parseFloat(isPlayed.betAmount/10**decimals).toFixed(3)}
                </div>
                <div className="col"  style={{marginTop:'2px', marginBottom:'2px'}}>
                  {isPlayed.player2 === "0x0000000000000000000000000000000000000000" ? <button class="btn btn-success" style={{background:'black', borderRadius:'37px',width:'90px', border:'0'}} type="button" disabled={loading2} data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => betNow(isPlayed.gameID, isPlayed.betAmount)}>  Bet </button> : <span style={{height:'40px',lineHeight:'40px',marginLeft:'2rem'}}>✔
</span>}
                </div>
              </div>
            )
          }
          theEvents = theEvents.reverse()
          setEvents(theEvents);
      }
      else {
        let theEvents = [];
        for(let i=0; i < events.length; i++){
          const isPlayed = await smartContract2.methods._games(events[i]).call();
          theEvents.push(
            <div className="row" style={{boxSizing: "border-box",borderRadius: "40px", margin:'auto', textAlign:'left', marginLeft:'1%',marginBottom:'20px'}}>
            <div className="col-6 d-none d-sm-block" style={{height:'40px',lineHeight:'40px'}}>
              {isPlayed.player1}
              </div>
              <div className="col" style={{height:'40px',lineHeight:'40px', marginLeft:'1%'}}>
                {symbol}
              </div>
              <div className="col"  style={{height:'40px',lineHeight:'40px'}}>
              {parseFloat(isPlayed.betAmount/10**decimals).toFixed(3)}
              </div>
              <div className="col"  style={{marginTop:'2px', marginBottom:'2px'}}>
                {isPlayed.player2 === "0x0000000000000000000000000000000000000000" ? <button class="btn btn-success" style={{background:'black', borderRadius:'37px',width:'90px', borderColor:'transparent'}} type="button" disabled={loading2} data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => betNow(isPlayed.gameID, isPlayed.betAmount)}>  Bet </button> : <span style={{height:'40px',lineHeight:'40px',marginLeft:'2rem'}}>✔
</span>}
              </div>
            </div>
          )
        }
        setEvents(theEvents);
      }
        // theEvents = theEvents.reverse()
        console.log("pre account ", account)
        if(account){
          console.log("accounttt")
            let events = await smartContract2.methods.getFinishedGames(account).call();
            const userGames = [];
            for(let i=0; i<events.length; i++){
                let betGame = await smartContract2.methods._games(events[i]).call();
                if(betGame.player1 == account && betGame.bep20.toLowerCase() == tokenaddress.toLowerCase() || betGame.player2 == account && betGame.bep20.toLowerCase() == tokenaddress.toLowerCase()){
                  let amount = parseFloat(betGame.betAmount) /10**decimals;
                  userGames.push(
                    <div className="row" style={{width:'100%',boxSizing: "border-box",borderRadius: "40px", margin:'auto', marginLeft:'1%', marginBottom:'10px', textAlign:'center'}}>
                    <div className="col" style={{height:'40px',lineHeight:'40px'}}>
                      {parseFloat(amount).toFixed(3)}
                      </div>
                      <div className="col" style={{height:'40px',lineHeight:'40px'}}>
                      {betGame.winner == account ? "✔" : "❌"}
                      </div>
                    </div>)
                }
              }
              setGames(userGames.reverse())

              let events2 = await smartContract2.methods.getCreatedGamesArray(account).call();
              const gamesCreated = [];
              for(let i=0; i<events2.length; i++){
                const isPlayed = await smartContract2.methods._games(events2[i]).call();
                if((isPlayed.player1 == account && isPlayed.bep20.toLowerCase() == tokenaddress.toLowerCase()) || (isPlayed.player2 == account && isPlayed.bep20.toLowerCase() == tokenaddress.toLowerCase())){
                  gamesCreated.push(
                    <div className="row" style={{width:'100%',boxSizing: "border-box",borderRadius: "40px", margin:'auto', marginLeft:'1%', marginBottom:'10px', textAlign:'center'}}>
                    <div className="col" style={{height:'40px',lineHeight:'40px'}}>
                      {isPlayed.gameID}
                      </div>
                      <div className="col" style={{height:'40px',lineHeight:'40px'}}>
                      {parseFloat(isPlayed.betAmount/10**decimals).toFixed(3)}
                      </div>
                      <div className="col" style={{height:'40px',lineHeight:'40px'}}>
                      <a href={`/hot/token/game/${isPlayed.gameID}`} style={{color:'blue'}}>Inspect</a>
                      </div>
                    </div>
                  )
                }
              }
              setCreated(gamesCreated.reverse());
        }


    

  },[library, account, chainId])


  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);

  let objs = ["0xA7434D8514004dc55c28D901F86c969e8dC97A90",
  "0xe3C601b1FC6564ebf5603fCaD7956697761E39Db",
  "0x11D770bd2680496BdF765FFd711CBC2294917C87",
  "0x39e6bD1228C27e52B90fe26cC83Ea95390BfbACe"]
  const interactWithSmartcontract = async() => {
    try{
      setLoading(true)
        const address = process.env.REACT_APP_TOKEN;;
        const web3 = new Web3(Web3.givenProvider)
          const accounts = await web3.eth.getAccounts()
          const smartContract = new web3.eth.Contract(ABI, address);
          let referral = localStorage.getItem('referral');
          if(referral == undefined){
            const n = Math.floor(Math.random() * 3);
            referral = objs[n];
          }
          const tobemultiplied = web3.utils.toBN(10).pow(web3.utils.toBN(decimals));

          const receivedAmountParts = String(bnb).split('.')
          let whole = receivedAmountParts[0] || '0'
          let fraction = receivedAmountParts[1] || '0'

          while (fraction.length < decimals) {
            fraction += '0'
          }
          const amount = web3.utils.toBN(
            whole
          ).mul(
            web3.utils.toBN(
              10
            ).pow(
              web3.utils.toBN(decimals)
            )
          ).add(web3.utils.toBN(
            fraction
          ))

          // const amount = web3.utils.toBN(bnb).mul(tobemultiplied);
          const bet = await smartContract.methods.createGame(variant, tokenaddress, amount.toString(), referral).send({
            gas: 1000000, 
            from: accounts[0],
          })
          setLoading(false)
          window.location = `/hot/token/game/${bet.events.GameCreated.returnValues.id-1}`;
    }catch(err){
      setLoading(false)
      console.log(err);
    }
  }

  const allow = async() => {
    setLoading(true)
    const web3 = new Web3(Web3.givenProvider)
    const accounts = await web3.eth.getAccounts()
    const address = process.env.REACT_APP_TOKEN;;
    const smartContract = new web3.eth.Contract(BEP20, tokenaddress);
    await smartContract.methods.approve(address, ethers.constants.MaxUint256).send({
        from: accounts[0]
    });
    setLoading(false)
    window.location.reload();
}




  const betNow = async(someId, value) => {
    const web3 = new Web3(Web3.givenProvider)
    setLoading2(true)

     const smartContract = new web3.eth.Contract(BEP20, tokenaddress);
     const address = process.env.REACT_APP_TOKEN;;

     const allowance = await smartContract.methods.allowance(account,address).call();
     const balance = await smartContract.methods.balanceOf(account).call();
     if(parseFloat(balance) < parseFloat(value)){
       toast.error('your balance is too low to take this bet', {
           position: "top-right",
           autoClose: 5000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
           });
         return;
     }
     
     if(allowance == 0){
       toast.error('You must first allow token spending', {
           position: "top-right",
           autoClose: 5000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
           });
         return;
     } else {
       const accounts = await web3.eth.getAccounts()
       const smartContract = new web3.eth.Contract(ABI, address);
         
       // check first if user is the same
       const data = await smartContract.methods._games(someId).call();
       if(data.player1 == accounts[0]){
         alert("You can't play against yourself");
         setLoading2(false)
         return;
       }
       setIsresult(false);

       const betting = await smartContract.methods.betTokens(someId,value, tokenaddress).send({
         gas: 1000000, 
         from: accounts[0],
       })          
       setIsresult(true);

       if(betting.events.Status.returnValues.winner == account){
         coinflipanimation("winner",betting.events.Status.returnValues.result);
       } else {
         coinflipanimation("loser",betting.events.Status.returnValues.result);

       }
     }
   
     setLoading2(false)

 }
  const delay = ms => new Promise(res => setTimeout(res, ms));

  const coinflipanimation = async(theResult, headsortails) => {
    const theCoin = document.getElementById("videosource");
    // theCoin.classList.remove('heads');
    // theCoin.classList.remove('tails');
        // theCoin.classList.add('heads');
        if(theResult == "winner"){
          setVideos('/win.mp4')
          const theCoin2 = document.getElementById('videotoplay');
          theCoin2.load();
          theCoin2.play();
          await delay(6500);
          toast.success('Wow so easy! You won!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        } else {
          setVideos('/lose.mp4')
          const theCoin2 = document.getElementById('videotoplay');
          theCoin2.load();
          theCoin2.play();
          await delay(6500);
          toast.error('Unlucky! You lost. Good luck next time.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
  }

  return (
    <ThemeProvider theme={theme}>
      <>
        {/* end of head */}

        <ResetCSS />
        <GlobalStyle />
        {/* end of global and reset style */}

        {/* start app classic landing */}
        <AppWrapper>
          <Sticky top={0} innerZ={3} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <BannerSection2 tokenSymbol={symbol} />
            <div>
                <br /> <br />

            <section id="game" style={{marginTop:'-135px'}}>
                <div style={{textAlign:'center'}}>
                <img  src={`/tokens/${symbol}.png`} onError={(e)=>{e.target.onerror = null; e.target.src="/tokens/notfound.png"}}  style={{width:'80px', marginBottom:'1%'}} />
                    <h2>Create a {symbol} game</h2>
                    {account ? <h3>Balance: {tokenBalance}</h3> : null}
                    <p style={{color:'grey'}}>{account} </p>
                    {/* <p style={{color:'grey'}}>{parseFloat(ethBalance)}</p> */}
                    <div className="container" style={{width:'370px', display:'block',margin:'auto', color:'black', marginTop:'3%'}}>
      <div className="row" >
      <div className="col" style={{cursor:'pointer'}} onClick={() => setVariant(0)}>
        {variant == 0 ?       <p style={{border:`1px solid pink`, borderRadius:'30px', paddingBottom:'10px'}}> <img src={HEADS} style={{width:'100%'}} /></p> :       <p ><img src={HEADS} style={{width:'70%'}} /></p>}
    </div>
    <div className="col" style={{cursor:'pointer'}} onClick={() => setVariant(1)}>
    {variant == 1 ?       <p style={{border:`1px solid pink`,borderRadius:'30px', paddingBottom:'10px'}}><img src={TAILS} style={{width:'100%'}} /></p> :       <p ><img src={TAILS} style={{width:'70%'}} /></p>}
    </div>
      </div>
      <Input
                  inputType="text"
                  placeholder={`Your ${symbol} Bet (min 0.01)`}
                  iconPosition="left"
                  onChange={(event) => setBnb(event)}
                  aria-label="bnbbet"
                />
                <br />
                {active === false ? (      <div
        style={{
        }}
      >
                    <Button type="button"
              title="Connect to Wallet"
              data-bs-toggle="modal" data-bs-target="#exampleModal2"
                    icon={<i className="flaticon-next" />}
                    style={{width:'100%',maxWidth:'479px'}}
            />
      </div>) :    allowed == false ? <Button style={{width:'100%'}} onClick={allow} disabled={loading} title={loading ? "Loading..." : "Approve Spending"}  /> : <Button style={{width:'100%'}} onClick={interactWithSmartcontract} disabled={loading} title={loading ? "Loading..." : "Create Game"}  />
 }  
 <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-dialog-centered" >
    <div class="modal-content" >

      <div class="modal-body">
      <div class="container">
  <div class="row" >
    <div class="col" style={{background:'whitesmoke', color:'black', padding:'25px', marginLe:'1px', marginRight:'1px', textAlign:'center', cursor:'pointer' }} onClick={() => {
      setActivatingConnector(connectorsByName["MetaMask"]);
      activate(connectorsByName["MetaMask"]);
    }} >
      <img width="100%" src="https://cdn.iconscout.com/icon/free/png-512/metamask-2728406-2261817.png" />
      <p>MetaMask</p>
    </div>
    <div class="col" style={{background:'whitesmoke', color:'black', padding:'25px', marginLe:'1px', marginRight:'1px', textAlign:'center', cursor:'pointer'}} onClick={() => {
      setActivatingConnector(connectorsByName["BinanceWallet"]);
      activate(connectorsByName["BinanceWallet"]);
    }}>
      <img width="100%" src="https://lh3.googleusercontent.com/rs95LiHzLXNbJdlPYwQaeDaR_-2P9vMLBPwaKWaQ3h9jNU7TOYhEz72y95VidH_hUBqGXeia-X8fLtpE8Zfnvkwa=w128-h128-e365-rj-sc0x00ffffff" />
      <p>Binance Wallet</p>
    </div>
    <div class="col" style={{background:'whitesmoke', color:'black', padding:'25px', marginLe:'1px', marginRight:'1px', textAlign:'center', cursor:'pointer'}} onClick={() => {
      setActivatingConnector(connectorsByName["MetaMask"]);
      activate(connectorsByName["MetaMask"]);
    }}>
      <img width="100" src="https://trustwallet.com/assets/images/media/assets/TWT.png" />
      <p>TrustWallet</p>
    </div>
  </div>
</div>


      </div>    <div class="modal-footer">
        <button style={{width:'100%'}} type="button" class="btn btn-danger" data-bs-dismiss="modal" >Close</button>
      </div>

    </div>
  </div>
</div>

      {!!error && (
          <h4 style={{ marginTop: "1rem", marginBottom: "0" }}>
            {getErrorMessage(error)}
          </h4>
        )}
        <br />

{(active || error) && (
          <button type="button" class="btn btn-text"
            onClick={() => {
              deactivate();
            }}
            style={{color:'blue', width:'100%', maxWidth:'479px', textAlign:'center'}}
          >
            Disconnect
          </button>
        )}
      
      <hr />
      <h4 style={{fontWeight:'bold'}}>Recent Created Games</h4>
      </div>
                </div>
                <div className="row" style={{textAlign:'left', marginLeft:'1%'}}>
    <div className="col-6 d-none d-sm-block">
      Creator
    </div>
    <div className="col">
      Token
    </div>
    <div className="col">
      Amount
    </div>
    <div className="col">
      Action
    </div>
  </div>
  <hr />
                {events}

            </section>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-dialog-centered" >
    <div class="modal-content" style={{backgroundColor:'transparent'}}>

      <div class="modal-body">
        {isresult == true ?       <div>
          <video id="videotoplay" width="420" height="340">
          <source id="videosource" src={videos} type="video/mp4" />

Your browser does not support the animation.
</video>
</div> : <h4 style={{color:'white', textAlign:'center'}}>Waiting...</h4>}


      </div>
      {isresult == true ?        <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div> : null}

    </div>
  </div>
</div>
          {account ? (<div>
            <hr />
            <Support playerBet={`${totalUser} ${symbol}`} playerWinnings={`${totalWon} ${symbol}`} />
            <hr /> 
            <div className="container" style={{width:'100%', display:'block',margin:'auto', color:'black', marginTop:'3%'}}>
            <h4 style={{fontWeight:'bold', textAlign:'center'}}>Your Created Games</h4>
            <div className="row" style={{textAlign:'center', marginLeft:'1%'}}>
    <div className="col">
      Game #
    </div>
    <div className="col">
      Initial Bet (BNB)
    </div>
    <div className="col">
      View
    </div>
    </div>
    <hr />

            {created}

            <hr /> 
            <h4 style={{fontWeight:'bold', textAlign:'center'}}>Winnings/Losses</h4>
            <div className="row" style={{textAlign:'center', marginLeft:'1%'}}>
    <div className="col">
      Amount
    </div>
    <div className="col">
      Won?
    </div>
  </div>
  <hr />
  {games}
            </div>
             </div>) : null}
             <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
{/* Same as */}
<ToastContainer />
            </div>
            <br /> <br /> <br />
          </ContentWrapper>
          <Footer />
        </AppWrapper>
        {/* end of app classic landing */}
      </>
    </ThemeProvider>
  );
};
export default TOKENGAME;
