import * as React from "react";
import { ThemeProvider } from 'styled-components';
import { theme } from '../common/theme/appModern';
import { ResetCSS } from '../common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from '../containers/AppModern/Navbar';
import Banner from '../containers/AppModern/Banner/index2';
import AppSlider from '../containers/AppModern/AppSlider';
import Features from '../containers/AppModern/Features';
import DashboardFeatures from '../containers/AppModern/Dashboard';
// import ProductSlide from 'containers/AppModern/ProductSlide';
import DesignedAndBuilt from '../containers/AppModern/DesignedAndBuilt';
import LocationSection from '../containers/Ride/LocationSelection';
import Feature from '../containers/Ride/Feature';
import Jackpot from '../common/assets/image/jackpot.png';

// import PricingPolicy from 'containers/AppModern/PricingPolicy';
// import TeamPortfolio from 'containers/AppModern/TeamPortfoilo';
// import Testimonial from 'containers/AppModern/Testimonial';
// import Newsletter from 'containers/AppModern/Newsletter';
import Footer from '../containers/AppModern/Footer';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from '../containers/AppModern/appModern.style';

import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import { Web3Provider } from "@ethersproject/providers";
import { formatEther } from "@ethersproject/units";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  injected, bscConnector
} from "../connectors";
import { useEagerConnect, useInactiveListener } from "../hooks";
import Web3 from 'web3';
import ABI from './lottery.json';
const axios = require('axios');

const connectorsByName = {
  Injected: injected
};

function getErrorMessage(error) {
  if (error instanceof NoEthereumProviderError) {
    return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network.";
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorFrame
  ) {
    return "Please authorize this website to access your Ethereum account.";
  } else {
    console.error(error);
    return "An unknown error occurred. Check the console for more details.";
  }
}

function Lottery () {

  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error
  } = context;

  

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState();
  React.useEffect(() => {
    console.log('running')
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const [ethBalance, setEthBalance] = React.useState();
  React.useEffect(() => {
    if (library && account) {
      let stale = false;

      library
        .getBalance(account)
        .then(balance => {
          if (!stale) {
            setEthBalance(balance);
          }
        })
        .catch(() => {
          if (!stale) {
            setEthBalance(null);
          }
        });

      return () => {
        stale = true;
        setEthBalance(undefined);
      };
    }
  }, [library, account, chainId]);


  const [balance, setBalance] = React.useState('');
  const [dollars, setDollars] = React.useState('');
  const [chance, setChance] = React.useState('')

  React.useEffect(async() => {
    const web3 = new Web3('https://bsc-dataseed1.binance.org:443');
    const contractAddress = process.env.REACT_APP_CONTRACT;
    const smartContract = new web3.eth.Contract(ABI, contractAddress);
    const balance = await web3.eth.getBalance(contractAddress);
    setBalance(parseFloat(web3.utils.fromWei(balance.toString(),'ether')).toFixed(2));
    const request = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd');
    setDollars((request.data.binancecoin.usd*web3.utils.fromWei(balance.toString(),'ether')).toFixed(2))

    if(account){
      const independentBet = await smartContract.methods.independentData(0,account).call();
      let winningChances = independentBet.bnbBet/balance*100;
      if(winningChances < 0.01){
        winningChances = "< 0.01"
        setChance(winningChances);
      } else {
        setChance(winningChances.toFixed(4));
      }
    }

  })

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);




  return (
    <ThemeProvider theme={theme}>
      <>
        {/* end of head */}

        <ResetCSS />
        <GlobalStyle />
        {/* end of global and reset style */}

        {/* start app classic landing */}
        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <Banner />
            <div>
              <br /> <br />
                <div style={{textAlign:'center',    border:" 1px solid pink", borderRadius:'25px',marginLeft: "10%",marginRight: "10%", paddingBottom:'3%'}}>
                  <img src={Jackpot} style={{width:'100%',maxWidth:'600px'}} />
                <div class="dark fire">
		    <h1 class="Blazing" contenteditable="true">${dollars}</h1>
	</div>                 
                <h4 style={{color:'grey'}}>{balance} BNB</h4>
                {account ? <div><p style={{fontWeight:'bold'}}>Your current chance: {chance}%</p> <p style={{color:'grey'}}>The more tickets, the higher your chances to win!</p></div>: null} 
                </div>
                <br /> <br />

                <LocationSection />
                <Feature />
            </div>
            <br /> <br /> <br />
          </ContentWrapper>
          <Footer />
        </AppWrapper>
        {/* end of app classic landing */}
      </>
    </ThemeProvider>
  );
};
export default Lottery;
