import React from 'react';
import Box from '../../../common/components/Box';
import Heading from '../../../common/components/Heading';
import Text from '../../../common/components/Text';
import Button from '../../../common/components/Button';
import Image from '../../../common/components/Image';
import Container from '../../../common/components/UI/Container';
import Fade from 'react-reveal/Fade';

import { BannerWrapper } from './bannerSection2.style';

import { BANNER_DATA } from '../../../common/data/SassMinimal';

const BannerSection2 = (props) => {
  return (
    <BannerWrapper id="banner_section">
      {BANNER_DATA.map((banner, index) => (
        <Container key={`banner-${index}`}>
          <Heading content={`${props.tokenSymbol} Heads or Tails (HOT)`} as="h3" />
          <Text content={banner.text} />
          <a href={banner.btnLink}>
              <Button title={banner.btnLabel} />
          </a>
          <Text as="span" content={banner.offerText} />
          <Fade bottom>
            <Box className="imageWrapper">

            </Box>
          </Fade>
        </Container>
      ))}
      ;
    </BannerWrapper>
  );
};

export default BannerSection2;
