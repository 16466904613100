import * as React from "react";
import { ThemeProvider } from 'styled-components';
import { theme } from '../common/theme/appModern';
import { ResetCSS } from '../common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from '../containers/AppModern/Navbar';
import Banner from '../containers/AppModern/Banner/index2';
import BannerSection from '../containers/SassMinimal/BannerSection';

import AppSlider from '../containers/AppModern/AppSlider';
import Features from '../containers/AppModern/Features';
import DashboardFeatures from '../containers/AppModern/Dashboard';
// import ProductSlide from 'containers/AppModern/ProductSlide';
import DesignedAndBuilt from '../containers/AppModern/DesignedAndBuilt';
import LocationSection from '../containers/Ride/LocationSelection';
import Feature from '../containers/Ride/Feature';
import Input from '../common/components/Input';
import Button from '../common/components/Button';
import Support from '../containers/AgencyDigital/Support';


// import PricingPolicy from 'containers/AppModern/PricingPolicy';
// import TeamPortfolio from 'containers/AppModern/TeamPortfoilo';
// import Testimonial from 'containers/AppModern/Testimonial';
// import Newsletter from 'containers/AppModern/Newsletter';
import Footer from '../containers/AppModern/Footer';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from '../containers/AppModern/appModern.style';

import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import { Web3Provider } from "@ethersproject/providers";
import { formatEther } from "@ethersproject/units";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  injected,bscConnector
} from "../connectors";
import { useEagerConnect, useInactiveListener } from "../hooks";
import Web3 from 'web3';
import ABI from './bnb_bet.json';
import HEADS from '../HEADS.png';
import TAILS from '../Tails.png';
import { Spinner } from "../Spinner";

const axios = require('axios');

const connectorsByName = {
  MetaMask: injected,
  BinanceWallet: bscConnector
};

function getErrorMessage(error) {
  if (error instanceof NoEthereumProviderError) {
    return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network.";
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorFrame
  ) {
    return "Please authorize this website to access your Ethereum account.";
  } else {
    console.error(error);
    return "An unknown error occurred. Refresh and try again. Make sure you are on Binance Smart Chain";
  }
}

function BNBGAME () {

  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error
  } = context;
  const [variant, setVariant] = React.useState(0);
  const [bnb, setBnb] = React.useState(0);
  const [isresult, setIsresult] = React.useState(false);
  const [videos, setVideos] = React.useState('');

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState();
  React.useEffect(() => {
    console.log('running')
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
      window.location.reload()
    }
  }, [activatingConnector, connector]);

  const [ethBalance, setEthBalance] = React.useState();
  React.useEffect(() => {
    if (library && account) {
      let stale = false;

      library
        .getBalance(account)
        .then(balance => {
          if (!stale) {
            setEthBalance(balance);
          }
        })
        .catch(() => {
          if (!stale) {
            setEthBalance(null);
          }
        });

      return () => {
        stale = true;
        setEthBalance(undefined);
      };
    }
  }, [library, account, chainId]);


  const [events, setEvents] = React.useState([])
  const [created, setCreated] = React.useState([])
  const [games, setGames] = React.useState([])


  React.useEffect(async () => {
    const web3 = new Web3('https://bsc-dataseed1.binance.org:443');
    const contractAddress = process.env.REACT_APP_BNB;
    const smartContract = new web3.eth.Contract(ABI, contractAddress);
    const query = new URLSearchParams(window.location.search);

    const token = query.get('ref')
    if(token !== undefined){
      const validAdd = web3.utils.isAddress(token);
      if(validAdd == true){
        localStorage.setItem('referral', token);
      }
    }

    let events = await smartContract.methods.getGameID().call();
    let theEvents = [];
    if(events > 5){
      for(let i=events-1; i > events-6; i--){
        const isPlayed = await smartContract.methods._games(i).call();
        theEvents.push(
          <div className="row" style={{border: "black",boxSizing: "border-box",borderRadius: "40px", margin:'auto', textAlign:'left', marginLeft:'1%', marginBottom:'20px'}}>
          <div className="col-6 d-none d-sm-block" style={{height:'40px',lineHeight:'40px'}}>
            {isPlayed.player1}
            </div>
            <div className="col" style={{height:'40px',lineHeight:'40px', marginLeft:'1%'}}>
              BNB
            </div>
            <div className="col"  style={{height:'40px',lineHeight:'40px'}}>
              {parseFloat(web3.utils.fromWei(isPlayed.betAmount, 'ether')).toFixed(3)}
            </div>
            <div className="col"  style={{marginTop:'2px', marginBottom:'2px'}}>
              {isPlayed.player2 === "0x0000000000000000000000000000000000000000" ? <button class="btn btn-success" style={{background:"black", borderRadius:'37px', borderColor:'transparent!important', width:'90px'}} data-bs-toggle="modal" data-bs-target="#exampleModal" type="button" disabled={loading2} onClick={() => betNow(isPlayed.gameID, isPlayed.betAmount)}>  Bet </button> : <span style={{height:'40px',lineHeight:'40px', marginLeft:'2rem'}}>✔</span>}
            </div>
          </div>
        )
      }
    } else {
      for(let i=0; i < events; i++){
        const isPlayed = await smartContract.methods._games(i).call();
        theEvents.push(
          <div className="row" style={{border: "black",boxSizing: "border-box",borderRadius: "40px", margin:'auto', textAlign:'left', marginLeft:'1%',marginBottom:'20px'}}>
          <div className="col-6 d-none d-sm-block" style={{height:'40px',lineHeight:'40px'}}>
            {isPlayed.player1}
            </div>
            <div className="col" style={{height:'40px',lineHeight:'40px', marginLeft:'1%'}}>
              BNB
            </div>
            <div className="col"  style={{height:'40px',lineHeight:'40px'}}>
              {parseFloat(web3.utils.fromWei(isPlayed.betAmount, 'ether')).toFixed(3)}
            </div>
            <div className="col"  style={{marginTop:'2px', marginBottom:'2px'}}>
              {isPlayed.player2 === "0x0000000000000000000000000000000000000000" ? <button class="btn btn-success" style={{background:"black", borderRadius:'37px', borderColor:'transparent!important', width:'90px'}} data-bs-toggle="modal" data-bs-target="#exampleModal" type="button" disabled={loading2} onClick={() => betNow(isPlayed.gameID, isPlayed.betAmount)}>  Bet </button> : <span style={{height:'40px',lineHeight:'40px',marginLeft:'2rem'}}>✔</span>}
            </div>
          </div>
        )
      }
    }

    // theEvents = theEvents.reverse()
    setEvents(theEvents);


  }, [library, account, chainId])


  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  let objs = ["0xA7434D8514004dc55c28D901F86c969e8dC97A90",
    "0xe3C601b1FC6564ebf5603fCaD7956697761E39Db",
    "0x11D770bd2680496BdF765FFd711CBC2294917C87",
    "0x39e6bD1228C27e52B90fe26cC83Ea95390BfbACe"]

  const interactWithSmartcontract = async() => {
    try{
      setLoading(true)
      const address = process.env.REACT_APP_BNB;;
          const web3 = new Web3(Web3.givenProvider)
          const accounts = await web3.eth.getAccounts()
          const smartContract = new web3.eth.Contract(ABI, address);
          let referral = localStorage.getItem('referral');
          if(referral == undefined){
            const n = Math.floor(Math.random() * 3);
            referral = objs[n];
          }
          const bet = await smartContract.methods.createGame(variant, referral).send({
            gas: 1000000, 
            from: accounts[0],
            value: bnb*10**18,
          })
          setLoading(false)
          window.location = `/hot/bnb/game/${bet.events.GameCreated.returnValues.id-1}`;
    }catch(err){
      console.log(err);
      setLoading(false)
    }

  }


  const [totalUser, setTotalUser] = React.useState(0);
  const [totalWon, setTotalWon] = React.useState(0);
  React.useEffect(async () => {
    if(library && account){
      const address = process.env.REACT_APP_BNB;
      const web3 = new Web3(Web3.givenProvider)
      const smartContract = new web3.eth.Contract(ABI, address);
      const gamesCreated = [];
      let events2 = await smartContract.methods.getCreatedGamesArray(account).call();
      for(let i=0; i<events2.length; i++){
        const isPlayed = await smartContract.methods._games(events2[i]).call();
        if(isPlayed.player1 == account){
          gamesCreated.push(
            <div className="row" style={{width:'100%',boxSizing: "border-box",borderRadius: "40px", margin:'auto', marginBottom:'10px', textAlign:'center'}}>
            <div className="col" style={{height:'40px',lineHeight:'40px'}}>
              {isPlayed.gameID}
              </div>
              <div className="col" style={{height:'40px',lineHeight:'40px'}}>
              {parseFloat(web3.utils.fromWei(isPlayed.betAmount,'ether')).toFixed(3)}
              </div>
              <div className="col" style={{height:'40px',lineHeight:'40px'}}>
              <a href={`/hot/bnb/game/${isPlayed.gameID}`} style={{color:'blue'}}>Inspect</a>
              </div>
            </div>
          )
        }
      }
      setCreated(gamesCreated.reverse());
      const totalUser = await smartContract.methods.betAmount(account).call()
      const won = await smartContract.methods.winnings(account).call()
      setTotalUser(web3.utils.fromWei(totalUser,'ether'))
      setTotalWon(web3.utils.fromWei(won,'ether'))

      let events = await smartContract.methods.getFinishedGames(account).call();
      const userGames = [];
      // games created by user  
      for(let i=0; i<events.length; i++){
        const isPlayed = await smartContract.methods._games(events[i]).call();
        if(isPlayed.player1 == account || isPlayed.player2 == account){
          userGames.push(
            <div className="row" style={{width:'100%',boxSizing: "border-box",borderRadius: "40px", margin:'auto', marginLeft:'1%', marginBottom:'10px', textAlign:'center'}}>
            <div className="col" style={{height:'40px',lineHeight:'40px'}}>
              {web3.utils.fromWei(isPlayed.betAmount,'ether')}
              </div>
              <div className="col" style={{height:'40px',lineHeight:'40px'}}>
              {isPlayed.winner == account ? "✔" : "❌"}
              </div>
            </div>)
        }
      }
      setGames(userGames.reverse())

    }
  },[library, account, chainId])


  const betNow = async(someId, value) => {
    setLoading2(true)
    const address = process.env.REACT_APP_BNB;
    const web3 = new Web3(Web3.givenProvider)
    const smartContract = new web3.eth.Contract(ABI, address);
    const data = await smartContract.methods._games(someId).call();
    if(data.player1 == account){
      alert("You can't play against yourself");
      setLoading2(false)
      setIsresult(false);
      window.location.reload();
      return;
    }
    setIsresult(false);

    const betting = await smartContract.methods.betTokens(someId).send({
      from: account,
      value: value
    })
    setIsresult(true);
    if(betting.events.Status.returnValues.winner == account){
      coinflipanimation("winner",betting.events.Status.returnValues.result);
    } else {
      coinflipanimation("loser",betting.events.Status.returnValues.result);
    }
    setLoading2(false)

  }
  const delay = ms => new Promise(res => setTimeout(res, ms));

  const coinflipanimation = async(theResult, headsortails) => {
    const theCoin = document.getElementById("videosource");
    // theCoin.classList.remove('heads');
    // theCoin.classList.remove('tails');
        // theCoin.classList.add('heads');
        if(theResult == "winner"){
          setVideos('/win.mp4')
          const theCoin2 = document.getElementById('videotoplay');
          theCoin2.load();
          theCoin2.play();
          await delay(6500);
          toast.success('Wow so easy! You won!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        } else {
          setVideos('/lose.mp4')
          const theCoin2 = document.getElementById('videotoplay');
          theCoin2.load();
          theCoin2.play();
          await delay(6500);
          toast.error('Unlucky! You lost. Good luck next time.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
  }

  return (
    <ThemeProvider theme={theme}>
      <>
        {/* end of head */}

        <ResetCSS />
        <GlobalStyle />
        {/* end of global and reset style */}

        {/* start app classic landing */}
        <AppWrapper>
          <Sticky top={0} innerZ={3} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <BannerSection />
            <div>
                <br /> <br />

            <section id="game">
                <div style={{textAlign:'center'}}>
                    <h2>Create a BNB game</h2>
                    <p style={{color:'grey'}}>{account} </p>
                    {/* <p style={{color:'grey'}}>{parseFloat(ethBalance)}</p> */}
                    <div className="container" style={{width:'370px', display:'block',margin:'auto', color:'black', marginTop:'3%'}}>
      <div className="row" >
      <div className="col" style={{cursor:'pointer'}} onClick={() => setVariant(0)}>
        {variant == 0 ?       <p style={{border:`1px solid pink`, borderRadius:'30px', paddingBottom:'10px'}}> <img src={HEADS} style={{width:'100%'}} /></p> :       <p ><img src={HEADS} style={{width:'70%'}} /></p>}
    </div>
    <div className="col" style={{cursor:'pointer'}} onClick={() => setVariant(1)}>
    {variant == 1 ?       <p style={{border:`1px solid pink`,borderRadius:'30px', paddingBottom:'10px'}}><img src={TAILS} style={{width:'100%'}} /></p> :       <p ><img src={TAILS} style={{width:'70%'}} /></p>}
    </div>
      </div>
      <Input
                  inputType="text"
                  placeholder="Your BNB Bet (min 0.01)"
                  iconPosition="left"
                  onChange={(event) => setBnb(event)}
                  aria-label="bnbbet"
                />
                <br />
                {active === false ? (      <div
        style={{
        }}
      >
            <Button type="button"
              title="Connect to Wallet"
              data-bs-toggle="modal" data-bs-target="#exampleModal2"
                    icon={<i className="flaticon-next" />}
                    style={{width:'100%',maxWidth:'479px'}}
            />
      </div>) :               <Button style={{width:'100%'}} onClick={interactWithSmartcontract} disabled={loading} title={loading ? "Loading..." : "Create Game"}  />
 }  
      {!!error && (
          <h4 style={{ marginTop: "1rem", marginBottom: "0" }}>
            {getErrorMessage(error)}
          </h4>
        )}
        <br />

        <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-dialog-centered" >
    <div class="modal-content" >

      <div class="modal-body">
      <div class="container">
  <div class="row" >
    <div class="col" style={{background:'whitesmoke', color:'black', padding:'25px', marginLe:'1px', marginRight:'1px', textAlign:'center', cursor:'pointer' }} onClick={() => {
      setActivatingConnector(connectorsByName["MetaMask"]);
      activate(connectorsByName["MetaMask"]);
    }} >
      <img width="100%" src="https://cdn.iconscout.com/icon/free/png-512/metamask-2728406-2261817.png" />
      <p>MetaMask</p>
    </div>
    <div class="col" style={{background:'whitesmoke', color:'black', padding:'25px', marginLe:'1px', marginRight:'1px', textAlign:'center', cursor:'pointer'}} onClick={() => {
      setActivatingConnector(connectorsByName["BinanceWallet"]);
      activate(connectorsByName["BinanceWallet"]);
    }}>
      <img width="100%" src="https://lh3.googleusercontent.com/rs95LiHzLXNbJdlPYwQaeDaR_-2P9vMLBPwaKWaQ3h9jNU7TOYhEz72y95VidH_hUBqGXeia-X8fLtpE8Zfnvkwa=w128-h128-e365-rj-sc0x00ffffff" />
      <p>Binance Wallet</p>
    </div>
    <div class="col" style={{background:'whitesmoke', color:'black', padding:'25px', marginLe:'1px', marginRight:'1px', textAlign:'center', cursor:'pointer'}} onClick={() => {
      setActivatingConnector(connectorsByName["MetaMask"]);
      activate(connectorsByName["MetaMask"]);
    }}>
      <img width="100" src="https://trustwallet.com/assets/images/media/assets/TWT.png" />
      <p>TrustWallet</p>
    </div>
  </div>
</div>


      </div>    <div class="modal-footer">
        <button style={{width:'100%'}} type="button" class="btn btn-danger" data-bs-dismiss="modal" >Close</button>
      </div>

    </div>
  </div>
</div>

{(active || error) && (
          <button type="button" class="btn btn-text"
            onClick={() => {
              deactivate();
            }}
            style={{color:'blue', width:'100%', maxWidth:'479px', textAlign:'center'}}
          >
            Disconnect
          </button>
        )}
      
      <hr />
      <h4 style={{fontWeight:'bold'}}>Recent Created Games</h4>
      </div>
                </div>
                <div className="row" style={{textAlign:'left', marginLeft:'1%'}}>
    <div className="col-6 d-none d-sm-block">
      Creator
    </div>
    <div className="col">
      Token
    </div>
    <div className="col">
      Amount
    </div>
    <div className="col">
      Action
    </div>
  </div>
  <hr />
                {events}

            </section>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-dialog-centered" >
    <div class="modal-content" style={{backgroundColor:'transparent'}}>

      <div class="modal-body">
        {isresult == true ?       <div>
          <video id="videotoplay" width="420" height="340">
          <source id="videosource" src={videos} type="video/mp4" />

Your browser does not support the animation.
</video>
</div> : <h4 style={{color:'white', textAlign:'center'}}>Waiting...</h4>}


      </div>
      {isresult == true ?        <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div> : null}

    </div>
  </div>
</div>
          {account ? (<div>
            <hr />
            <Support playerBet={`${totalUser} BNB`} playerWinnings={`${totalWon} BNB`} />
            <hr /> 
            <div className="container" style={{width:'100%', display:'block',margin:'auto', color:'black', marginTop:'3%'}}>
            <h4 style={{fontWeight:'bold', textAlign:'center'}}>Your Created Games</h4>
            <div className="row" style={{textAlign:'center', marginLeft:'1%'}}>
    <div className="col">
      Game #
    </div>
    <div className="col">
      Initial Bet (BNB)
    </div>
    <div className="col">
      View
    </div>
    </div>
    <hr />

            {created}

            <hr /> 
            <h4 style={{fontWeight:'bold', textAlign:'center'}}>Winnings/Losses</h4>
            <div className="row" style={{textAlign:'center', marginLeft:'1%'}}>
    <div className="col">
      Amount
    </div>
    <div className="col">
      Won?
    </div>
  </div>
  <hr />
  {games}
            </div>
             </div>) : null}
             <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
{/* Same as */}
<ToastContainer />
            </div>
            <br /> <br /> <br />
          </ContentWrapper>
          <Footer />
        </AppWrapper>
        {/* end of app classic landing */}
      </>
    </ThemeProvider>
  );
};
export default BNBGAME;
