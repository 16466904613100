import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';
import { iosNavigate } from 'react-icons-kit/ionicons/iosNavigate';
import Box from '../../../common/components/Box';
import Text from '../../../common/components/Text';
import Button from '../../../common/components/Button';
import Card from '../../../common/components/Card';
import Image from '../../../common/components/Image';
import Input from '../../../common/components/Input';
import FeatureBlock from '../../../common/components/FeatureBlock';
import Container from '../../../common/components/UI/Container';
import { LocationSelectorWrap } from './locationSelection.style';
import ImageOne from '../../../common/assets/image/ride/estimate.svg';
import CarImage from '../../../common/assets/image/ride/car.svg';
import VejpaImage from '../../../common/assets/image/ride/vejpa.svg';
import { useEagerConnect, useInactiveListener } from "../../../hooks";
import { Spinner } from "../../../Spinner";
import { ToastContainer, toast } from 'react-toastify';
import ABI from '../../../pages/lottery.json';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";


import 'react-toastify/dist/ReactToastify.css';
import Web3 from 'web3'

import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";
import {
  injected, bscConnector
} from "../../../connectors";

const LocationSection = ({
  sectionWrapper,
  row,
  col,
  description,
  textArea,
  imageArea,
  imageAreaRow,
  imageWrapper,
  imageOne,
  imageWrapperOne,
  sectionSubTitle,
  estimateBtnStyle,
}) => {
  const [state, setState] = useState({
    active: true,
  });
  const activeStatus = state.active;

  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error
  } = context;
  const connectorsByName = {
    MetaMask: injected,
    BinanceWallet: bscConnector
  };

  const [activatingConnector, setActivatingConnector] = React.useState();
  React.useEffect(() => {
    console.log('running')
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
      window.location.reload()
    }
  }, [activatingConnector, connector]);


    // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
    const triedEager = useEagerConnect();

    function isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }

  const myF = async() => {
    try{
      if(isNumeric(bnb) && bnb >= 0.1){
        interactWithSmartcontract();
      } else {
        toast.error('Error! Pass a number that is equal or higher to 0.1 BNB', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch(err){
      console.log(err);
    }
  }

  
  const [loading, setLoading] = React.useState(false);
  const interactWithSmartcontract = async() => {
    try{
      setLoading(true)
      const address = process.env.REACT_APP_CONTRACT;
          const web3 = new Web3(Web3.givenProvider)
          const accounts = await web3.eth.getAccounts()
          const smartContract = new web3.eth.Contract(ABI, address);
          await smartContract.methods.participateInGame().send({
            from: accounts[0],
            value: bnb*10**18
          })
          setLoading(false)
          window.location.reload();
    }catch(err){
      console.log(err);
      setLoading(false)
    }

      }

  const [bnb, setBnb] = React.useState('');

  function getErrorMessage(error) {
    if (error instanceof NoEthereumProviderError) {
      return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
    } else if (error instanceof UnsupportedChainIdError) {
      return "You're connected to an unsupported network.";
    } else if (
      error instanceof UserRejectedRequestErrorInjected ||
      error instanceof UserRejectedRequestErrorFrame
    ) {
      return "Please authorize this website to access your Ethereum account.";
    } else {
      console.error(error);
      return "An unknown error occurred. Check the console for more details.";
    }
  }



  return (
    <LocationSelectorWrap id="fare_section">
      <Box {...sectionWrapper} id="control">
        <Container fullWidth noGutter className="control-sec-container">
          <Box {...row} {...imageAreaRow}>
            <Box {...col} {...imageArea}>
              <Card {...imageWrapper} {...imageWrapperOne}>
                <Fade left>
                  <Image src={ImageOne} alt="Info Image One" {...imageOne} />
                </Fade>
              </Card>
            </Box>

            <Box {...col} {...textArea} className="textArea">
              <Text content="BNB LOTTERY" {...sectionSubTitle} />
              <FeatureBlock
                description={
                  <Text
                    content="Participate with BNB and win the jackpot. Minimum 0.1 BNB"
                    {...description}
                  />
                }
              />
              <Box className="locationSelector">
                  <Input
                    inputType="email"
                    onChange={(event) => setBnb(event)}
                    placeholder="Amount"
                    iconPosition="right"
                    aria-label="pickup location"
                    style={{width:'100%'}}
                  />
                  <div className="locationColor" />
               
              </Box>
              <br />
              {active === false ? (      <div
        style={{
        }}
      >
                    <Button type="button"
              title={`Connect to Wallet`}
              data-bs-toggle="modal" data-bs-target="#exampleModal"
              variant="textButton"
                    icon={<i className="flaticon-next" />}
                    {...estimateBtnStyle}
                    style={{width:'100%',maxWidth:'479px', marginTop:'3%'}}
            />
      </div>) : <Button
                    title={loading === false ? "Enter Lottery" : "Loading..."}
                    variant="textButton"
                    onClick={myF}
                    disabled={loading}
                    icon={<i className="flaticon-next" />}
                    {...estimateBtnStyle}
                    style={{width:'100%',maxWidth:'479px'}}
                  />}
<br />

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-dialog-centered" >
    <div class="modal-content" >

      <div class="modal-body">
      <div class="container">
  <div class="row" >
    <div class="col" style={{background:'whitesmoke', color:'black', padding:'25px', marginLe:'1px', marginRight:'1px', textAlign:'center', cursor:'pointer' }} onClick={() => {
      setActivatingConnector(connectorsByName["MetaMask"]);
      activate(connectorsByName["MetaMask"]);
    }} >
      <img width="100%" src="https://cdn.iconscout.com/icon/free/png-512/metamask-2728406-2261817.png" />
      <p>MetaMask</p>
    </div>
    <div class="col" style={{background:'whitesmoke', color:'black', padding:'25px', marginLe:'1px', marginRight:'1px', textAlign:'center', cursor:'pointer'}} onClick={() => {
      setActivatingConnector(connectorsByName["BinanceWallet"]);
      activate(connectorsByName["BinanceWallet"]);
    }}>
      <img width="100%" src="https://lh3.googleusercontent.com/rs95LiHzLXNbJdlPYwQaeDaR_-2P9vMLBPwaKWaQ3h9jNU7TOYhEz72y95VidH_hUBqGXeia-X8fLtpE8Zfnvkwa=w128-h128-e365-rj-sc0x00ffffff" />
      <p>Binance Wallet</p>
    </div>
    <div class="col" style={{background:'whitesmoke', color:'black', padding:'25px', marginLe:'1px', marginRight:'1px', textAlign:'center', cursor:'pointer'}} onClick={() => {
      setActivatingConnector(connectorsByName["MetaMask"]);
      activate(connectorsByName["MetaMask"]);
    }}>
      <img width="100" src="https://trustwallet.com/assets/images/media/assets/TWT.png" />
      <p>TrustWallet</p>
    </div>
  </div>
</div>


      </div>    <div class="modal-footer">
        <button style={{width:'100%'}} type="button" class="btn btn-danger" data-bs-dismiss="modal" >Close</button>
      </div>

    </div>
  </div>
</div>

{!!error && (
          <h4 style={{ marginTop: "1rem", marginBottom: "0" }}>
            {getErrorMessage(error)}
          </h4>
        )}
        <br />

{(active || error) && (
          <button type="button" class="btn btn-text"
            onClick={() => {
              deactivate();
            }}
            style={{color:'blue', width:'100%', maxWidth:'479px', textAlign:'center'}}
          >
            Disconnect
          </button>
        )}
        
        <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
{/* Same as */}
<ToastContainer />

              {/* <Box className="derviceSelector">
                <div className="deviceSelectorWrapper">
                  <Box
                    className={
                      activeStatus ? 'vejpaImage active-item' : 'vejpaImage'
                    }
                    onClick={() => setState({ active: true })}
                  >
                    <Image src={VejpaImage} alt="Vejpa Image" />
                  </Box>
                  <Box
                    className="carImage"
                    className={
                      activeStatus === false
                        ? 'carImage active-item'
                        : 'carImage'
                    }
                    onClick={() => setState({ active: false })}
                  >
                    <Image src={CarImage} alt="Car Image" />
                  </Box>
                </div>
                <div className="derviceSelectorBtn">
                  <Button
                    title="Get Estimate"
                    variant="textButton"
                    icon={<i className="flaticon-next" />}
                    {...estimateBtnStyle}
                  />
                </div>
              </Box> */}
            </Box>
          </Box>
        </Container>
      </Box>
    </LocationSelectorWrap>
  );
};

LocationSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  description: PropTypes.object,
  estimateBtnStyle: PropTypes.object,
};

LocationSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['0px', '0px'],
    pb: ['0px', '0px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '0px',
    // pl: '15px',
  },
  textArea: {
    width: ['100%', '60%', '52%', '45%', '45%'],
    pl: ['0px', '15px', '30px', '60px', '60px'],
    m: ['0px 30px', 0],
  },
  imageArea: {
    width: ['0px', '40%', '48%', '55%', '55%'],
    flexBox: true,
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  imageWrapperOne: {
    pointerEvents: 'none',
    width: '100%',
  },
  imageOne: {
    width: '100%',
  },
  sectionSubTitle: {
    as: 'span',
    fontSize: ['22px', '26px', '26px', '30px', '36px'],
    fontWeight: '600',
    color: '#15172C',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '30px'],
    textAlign: 'center',
    fontFamily: 'Poppins',
  },
  description: {
    lineHeight: ['28px', '32px', '32px', '32px', '32px'],
    mt: ['20px', '30px', '30px', '30px', '30px'],
    mb: ['30px', '30px', '30px', '35px', '35px'],
    maxWidth: ['100%', '100%', '100%', '320px', '320px'],
    textAlign: ['left', 'left'],
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#15172C',
    fontFamily: 'Lato',
  },
  estimateBtnStyle: {
    type: 'button',
    minWidth: '160px',
    minHeight: '47px',
    fontSize: '14px',
    fontWeight: '700',
    fontFamily: 'Lato',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    pb: '2px',
    colors: 'primaryWithBg',
  },
};

export default LocationSection;
