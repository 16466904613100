/* ------------------------------------ */
// Navbar data section
/* ------------------------------------ */

//common/assets/image/appModern/logo.png
import logo from '../../../common/assets/image/appModern/Logo.png';
/* ------------------------------------ */
// client data section
/* ------------------------------------ */
import client1 from '../../../common/assets/image/appModern/company1.png';
import client2 from '../../../common/assets/image/appModern/company2.png';
import client3 from '../../../common/assets/image/appModern/company3.png';
import client4 from '../../../common/assets/image/appModern/company4.png';
/* ------------------------------------ */
// Features data section
/* ------------------------------------ */
import featureIcon1 from '../../../common/assets/image/appModern/icon1.svg';
import featureIcon2 from '../../../common/assets/image/appModern/icon2.svg';
import featureIcon3 from '../../../common/assets/image/appModern/icon3.svg';
import featureIcon4 from '../../../common/assets/image/appModern/icon4.svg';


/* ------------------------------------ */
// Product  Slide  section
/* ------------------------------------ */
import slide1 from '../../../common/assets/image/appModern/page1.png';
import slide2 from '../../../common/assets/image/appModern/page2.png';
import slide3 from '../../../common/assets/image/appModern/page3.png';

/* ------------------------------------ */
// App slider data section
/* ------------------------------------ */
import appSlide1 from '../../../common/assets/image/appModern/appSlider1.png';
import appSlide2 from '../../../common/assets/image/appModern/appSlider2.png';
import appSlide3 from '../../../common/assets/image/appModern/appSlider3.png';
import appIcon from '../../../common/assets/image/appModern/icon1.svg';

/* ------------------------------------ */
// Design and built data section
/* ------------------------------------ */
import codingImage from '../../../common/assets/image/appModern/code.png';

/* ------------------------------------ */
// Team Portfolio data section
/* ------------------------------------ */
import member1 from '../../../common/assets/image/appModern/1.png';
import member2 from '../../../common/assets/image/appModern/2.png';
import member3 from '../../../common/assets/image/appModern/3.png';
import member4 from '../../../common/assets/image/appModern/4.png';
import member5 from '../../../common/assets/image/appModern/5.png';
import member6 from '../../../common/assets/image/appModern/6.png';
import member7 from '../../../common/assets/image/appModern/7.png';


/* ------------------------------------ */
// Footer data section
/* ------------------------------------ */
import chat from '../../../common/assets/image/appModern/chat.svg';
import group from '../../../common/assets/image/appModern/group.svg';
// import github from 'common/assets/image/appModern/icon3.svg';
import footerLogo from '../../../common/assets/image/appModern/logoWhite.png';

export const navbar = {
  logo: logo,
  navMenu: [
    {
      id: 1,
      label: 'Home',
      path: '/',
      offset: '84',
    },
    {
      id: 2,
      label: 'Top Games',
      path: '#features',
      offset: '81',
    },
    {
      id: 3,
      label: 'Lottery',
      path: '/lottery',
      offset: '81',
    },
    {
      id: 4,
      label: 'Heads or Tails',
      path: '/choose',
      offset: '81',
    },
    {
      id: 5,
      label: 'Referral',
      path: '/earn',
      offset: '81',
    },
    {
      id: 6,
      label: 'Back to Staking',
      path: 'https://poolpartyfinance.io/',
      offset: '81',
    },
  ],
};


export const client = [
  {
    id: 1,
    image: client1,
    title: 'The new york times',
  },
  {
    id: 2,
    image: client2,
    title: 'amazon',
  },
  {
    id: 3,
    image: client3,
    title: 'evernote',
  },
  {
    id: 4,
    image: client4,
    title: 'the verge',
  },
];



export const features = {
  slogan: 'KEY FEATURES',
  title: 'Why you choose us',
  items: [
    {
      id: 1,
      color: '#F55767',
      icon: featureIcon1,
      title: 'Fast',
      description:
        'We operate on Binance Smart Chain with 3 seconds block confirmation time. Our games on average are 10x faster than on ETH chain',
    },
    {
      id: 2,
      color: '#ff4742',
      icon: featureIcon2,
      title: 'Blockchain Secure',
      description:
        'Our services are based on smart contracts and secured by blockchain technology',
    },
    {
      id: 3,
      color: '#fb5781',
      icon: featureIcon3,
      title: 'Top Choice',
      description:
        'Bet against other players and win up to 200% of your bet amount',
    },
    {
      id: 4,
      color: '#f18e47',
      icon: featureIcon4,
      title: 'Community',
      description:
        'We have a large community of 5,000 members and still growing ',
    },
  ],
};



export const appSlider = {
  carousel: [
    {
      id: 1,
      image: appSlide1,
      title: 'App Slide 1',
    },
    {
      id: 2,
      image: appSlide2,
      title: 'App Slide 1',
    },
    {
      id: 3,
      image: appSlide3,
      title: 'App Slide 1',
    },
  ],
  title: 'Super Lottery, Super Jackpots',
  description:
    "The rise of decentralized technology transforms the way we play games. This time, they are 100% random.",
  features: [
    {
      id: 1,
      icon: appIcon,
      title: 'Super Secure Randomness',
      description: 'We bring an innovative solution that uses multiple oracles to define randomness',
    },
    {
      id: 2,
      icon: appIcon,
      title: '100% Fair',
      description:
        'The only way to get favorable odds is through purchasing more tickets',
    },
    {
      id: 3,
      icon: appIcon,
      title: 'Winner takes it all',
      description: "Isn't this what jackpots are about?",
    },
  ],
};



export const designAndBuilt = {
  image: codingImage,
  slogan: 'Brought to you by',
  title: 'PoolPartyFinance',
  description:
    'Pool Party Finance started by offering DeFi solution to its 5,000 community members and has reached a staked value of over $500k. A business you can trust',
};



export const productData = {
  slogan: 'PRODUCT SHOWCASE',
  title: 'Meet Client Satisfaction by using our product',
  carousel: [
    {
      id: 1,
      thumb_url: slide1,
      link: '#1',
      title: 'slide 1',
    },
    {
      id: 2,
      thumb_url: slide2,
      link: '#1',
      title: 'slide 2',
    },
    {
      id: 3,
      thumb_url: slide3,
      link: '#1',
      title: 'slide 3',
    },

    {
      id: 4,
      thumb_url: slide1,
      link: '#1',
      title: 'slide 4',
    },

    {
      id: 5,
      thumb_url: slide3,
      link: '#1',
      title: 'slide 5',
    },
    {
      id: 6,
      thumb_url: slide2,
      link: '#1',
      title: 'slide 6',
    },
  ],
};

/* ------------------------------------ */
// Pricing policy data section
/* ------------------------------------ */
export const pricing = {
  slogan: 'PRICING PLAN',
  title: 'Choose your pricing policy',
  monthly: [
    {
      id: 1,
      title: 'Business Class',
      description: 'For Small teams or office',
      suggested: false,
      price: 0,
      features: [
        {
          id: 1,
          text: 'Drag & Drop Builder',
        },
        {
          id: 2,
          text: "1,000's of Templates",
        },
        {
          id: 3,
          text: 'Blog Support Tools',
        },
        {
          id: 4,
          text: 'eCommerce Store ',
        },
      ],
    },
    {
      id: 2,
      title: 'Pro Master',
      description: 'For Best opportunities',
      suggested: true,
      price: 99,
      trail: 14,
      trailLink: '#',
      features: [
        {
          id: 1,
          text: 'Drag & Drop Builder',
        },
        {
          id: 2,
          text: "1,000's of Templates",
        },
        {
          id: 3,
          text: 'Blog Support Tools',
        },
        {
          id: 4,
          text: 'eCommerce Store ',
        },
      ],
    },
  ],
  annualy: [
    {
      id: 1,
      title: 'Pro Master',
      description: 'For Small teams or office',
      suggested: true,
      price: 999,
      trail: 14,
      trailLink: '#',
      features: [
        {
          id: 1,
          text: 'Drag & Drop Builder',
        },
        {
          id: 2,
          text: "1,000's of Templates",
        },
        {
          id: 3,
          text: 'Blog Support Tools',
        },
        {
          id: 4,
          text: 'eCommerce Store ',
        },
      ],
    },
    {
      id: 2,
      title: 'Enterprise',
      description: 'For Best opportunities',
      suggested: false,
      price: 1299,
      trail: 30,
      trailLink: '#',
      features: [
        {
          id: 1,
          text: 'Drag & Drop Builder',
        },
        {
          id: 2,
          text: "1,000's of Templates",
        },
        {
          id: 3,
          text: 'Blog Support Tools',
        },
        {
          id: 4,
          text: 'eCommerce Store ',
        },
      ],
    },
  ],
};



export const teamportfolio = {
  title: 'Meet our awesome team members, work behind the sense',
  description:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiusmod tempor incididunt labore dolore features that Lorem ipsum dolor sit amet consectetur.',

  teammember: [
    {
      id: 1,
      img: member1,
      text: 'Berlin Corleone',
    },
    {
      id: 2,
      img: member2,
      text: 'Jona White',
    },
    {
      id: 3,
      img: member3,
      text: 'Michael Price',
    },
    {
      id: 4,
      img: member4,
      text: 'Gullyboy Rana',
    },
    {
      id: 5,
      img: member5,
      text: 'Miss Clair',
    },
    {
      id: 6,
      img: member6,
      text: 'Bucky Ali',
    },
    {
      id: 7,
      img: member7,
      text: 'Arthus Doe',
    },
  ],
};

/* ------------------------------------ */
// Testimonial data section
/* ------------------------------------ */
export const testimonial = {
  slogan: 'TESTIMONIAL',
  title: 'Meet Client Satisfaction by using product',
  reviews: [
    {
      id: 1,
      title: 'Modern look & trending design',
      description:
        'Get working experience to work with this amazing team & in future want to work together for bright future projects and also make deposit to freelancer.',
      avatar:
        'https://pbs.twimg.com/profile_images/974736784906248192/gPZwCbdS.jpg',
      name: 'Jon Doe',
      designation: 'CEO of RedQ Inc.',
      review: 4,
    },
    {
      id: 2,
      title: 'User friendly & Customizable',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiusmod tempor incididunt labore dolore features Lorem ipsum dolor sit amet consectetur adipisicing.',
      avatar: 'https://randomuser.me/api/portraits/women/44.jpg',
      name: 'Jeny Doe',
      designation: 'Co Founder of RedQ Inc.',
      review: 5,
    },
    {
      id: 3,
      title: 'User friendly & Customizable',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit sed eiusmod tempor incididunt labore dolore features Lorem ipsum dolor sit amet consectetur adipisicing.',
      avatar:
        'https://tinyfac.es/data/avatars/475605E3-69C5-4D2B-8727-61B7BB8C4699-500w.jpeg',
      name: 'Jon Doe',
      designation: 'Co Founder of RedQ Inc.',
      review: 5,
    },
  ],
};



export const footer = {
  widgets: [
    {
      id: 1,
      icon: chat,
      title: 'Join the Community',
      description:
        'Join us on telegram at t.me/poolpartyfinance',
    },
    {
      id: 2,
      icon: group,
      title: 'Follow us',
      description:
        'Follow us on Twitter at twitter.com/poolpartyfinan1',
    },
    // {
    //   id: 3,
    //   icon: github,
    //   title: 'Choose the Best',
    //   description:
    //     'Play our decentralized, limitless games',
    // },
  ],
  logo: footerLogo,
  menu: [
    {
      id: 1,
      text: 'Home',
      link: '/',
    },
    {
      id: 2,
      text: 'Lottery',
      link: '/lottery',
    },
    {
      id: 3,
      text: 'HOT',
      link: '/choose',
    },
  ],
};
