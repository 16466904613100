import * as React from "react";
import { ThemeProvider } from 'styled-components';
import { theme } from '../common/theme/appModern';
import { ResetCSS } from '../common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from '../containers/AppModern/Navbar';
import {
    useParams
  } from "react-router";

// import PricingPolicy from 'containers/AppModern/PricingPolicy';
// import TeamPortfolio from 'containers/AppModern/TeamPortfoilo';
// import Testimonial from 'containers/AppModern/Testimonial';
// import Newsletter from 'containers/AppModern/Newsletter';
import Footer from '../containers/AppModern/Footer';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from '../containers/AppModern/appModern.style';

import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import { Web3Provider } from "@ethersproject/providers";
import { formatEther } from "@ethersproject/units";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  injected, bscConnector
} from "../connectors";
import { useEagerConnect, useInactiveListener } from "../hooks";
import Web3 from 'web3';
import ABI from './bnb_bet.json';
import HEADS from '../HEADS.png';
import TAILS from '../Tails.png';
import Button from '../common/components/Button';
import { Spinner } from "../Spinner";

const axios = require('axios');

const connectorsByName = {
  MetaMask: injected,
  BinanceWallet: bscConnector
};

function getErrorMessage(error) {
  if (error instanceof NoEthereumProviderError) {
    return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network.";
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorFrame
  ) {
    return "Please authorize this website to access your Ethereum account.";
  } else {
    console.error(error);
    return "An unknown error occurred. Check the console for more details.";
  }
}

function TOKENSINGLEGAME () {

  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error
  } = context;
  const [variant, setVariant] = React.useState(0);
  const [bnb, setBnb] = React.useState(0);
  const [isresult, setIsresult] = React.useState(false);
  const [videos, setVideos] = React.useState('');

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState();
  React.useEffect(() => {
    console.log('running')
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
      window.location.reload()
    }
  }, [activatingConnector, connector]);

  const [ethBalance, setEthBalance] = React.useState();
  React.useEffect(() => {
    if (library && account) {
      let stale = false;

      library
        .getBalance(account)
        .then(balance => {
          if (!stale) {
            setEthBalance(balance);
          }
        })
        .catch(() => {
          if (!stale) {
            setEthBalance(null);
          }
        });

      return () => {
        stale = true;
        setEthBalance(undefined);
      };
    }
  }, [library, account, chainId]);


  const [amount, setAmount] = React.useState(0);
  const [amount2, setAmount2] = React.useState(0);
  const [choice, setChoice] = React.useState(0);
  const [creator, setCreator] = React.useState('');
  const [winner, setWinner] = React.useState('');

  React.useEffect(async () => {
      try{
        // const web3 = new Web3('https://bsc-dataseed1.binance.org:443');
        console.log("provider ", Web3.givenProvider)

        let web3;
        if(Web3.givenProvider == null){
            web3 = new Web3('https://bsc-dataseed1.binance.org:443');
        } else {
            web3 = new Web3(Web3.givenProvider);
        }
        const contractAddress = process.env.REACT_APP_BNB;
        const smartContract = new web3.eth.Contract(ABI, contractAddress);
        const game = await smartContract.methods._games(id).call();
        setAmount(parseFloat(web3.utils.fromWei(game.betAmount, 'ether')).toFixed(3))
        setAmount2(game.betAmount);
        setChoice(game.player1_bet);
        setCreator(game.player1);
        setWinner(game.winner);
      } catch(err){
          console.log("err is ", err);
      }



  }, [library, account, chainId])


  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  let { id } = useParams();








  const betNow = async() => {
    setIsresult(false);
    setLoading2(true)
    const someId = id;
    const value = amount2;
    const address = process.env.REACT_APP_BNB;
    const web3 = new Web3(Web3.givenProvider)
        const smartContract = new web3.eth.Contract(ABI, address);

        // check first if user is the same
        const data = await smartContract.methods._games(someId).call();

        if(data.player1 == account){
          alert("You can't play against yourself");
          setLoading2(false)
          window.location.reload();
          return;
        }

        const betting = await smartContract.methods.betTokens(someId).send({
          from: account,
          value: value
        })
        setIsresult(true);

        if(betting.events.Status.returnValues.winner == account){
          coinflipanimation("winner",betting.events.Status.returnValues.result);
        } else {
          coinflipanimation("loser",betting.events.Status.returnValues.result);

        }
    setLoading2(false)

}

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const coinflipanimation = async(theResult, headsortails) => {
    const theCoin = document.getElementById("videosource");
    // theCoin.classList.remove('heads');
    // theCoin.classList.remove('tails');
        // theCoin.classList.add('heads');
        if(theResult == "winner"){
          setVideos('/win.mp4')
          const theCoin2 = document.getElementById('videotoplay');
          theCoin2.load();
          theCoin2.play();
          await delay(6500);
          toast.success('Wow so easy! You won!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        } else {
          setVideos('/lose.mp4')
          const theCoin2 = document.getElementById('videotoplay');
          theCoin2.load();
          theCoin2.play();
          await delay(6500);
          toast.error('Unlucky! You lost. Good luck next time.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }

      }

  return (
    <ThemeProvider theme={theme}>
      <>
        {/* end of head */}

        <ResetCSS />
        <GlobalStyle />
        {/* end of global and reset style */}

        {/* start app classic landing */}
        <AppWrapper>
          <Sticky top={1} innerZ={3} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <div>
                <br /> <br />

            <section id="game" style={{marginTop:'8%'}}>
                <div style={{textAlign:'center'}}>
                    <h2>BNB Game</h2>
                    <a href="/hot/bnb">
                        Back to BNB Games
                    </a>
                    <p style={{color:'grey'}}>{account} </p>
                    <div class="card" style={{padding:'25px', marginLeft:'5%', marginRight:'5%',boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',background:'linear-gradient(45deg, pink, white)'}}>              

                    <h3>Bet Amount: <span style={{fontWeight:'bold'}}> {amount} BNB</span></h3>
                    <p style={{color:"black"}}>Game creator choice: <span style={{fontWeight:'bold'}}>  {choice == 0 ? "HEADS" : "TAILS"}</span> </p>
                    <p style={{color:"black"}}>Game creator: <span style={{fontWeight:'bold'}}> {creator}</span> </p>
                    {creator == account && winner=="0x0000000000000000000000000000000000000000" ? (<div class="alert alert-primary" style={{background:'lightsalmon'}} role="alert">
                    Invite someone to bet against you by sharing this link
  <br />
  <a href={window.location.href}>{window.location.href}</a>
</div>) : null}
{winner == "0x0000000000000000000000000000000000000000" ? (    <button type="button" style={{background:"hotpink", borderRadius:'37px', borderColor:'transparent'}} className="btn btn-primary" disabled={loading2} onClick={betNow} data-bs-toggle="modal" data-bs-target="#exampleModal"> Try your Luck</button>) : (<p>Won by <span style={{fontWeight:'bold'}}> {winner}</span></p>) }
</div>

<br /> <br />
{active === false ? (      <div
        style={{
        }}
      >
                    <Button type="button"
              title="Connect to Wallet"
              data-bs-toggle="modal" data-bs-target="#exampleModal"
                    icon={<i className="flaticon-next" />}
                    style={{width:'100%',maxWidth:'479px'}}
            />
      </div>)  : null}

      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-dialog-centered" >
    <div class="modal-content" >

      <div class="modal-body">
      <div class="container">
  <div class="row" >
    <div class="col" style={{background:'whitesmoke', color:'black', padding:'25px', marginLe:'1px', marginRight:'1px', textAlign:'center', cursor:'pointer' }} onClick={() => {
      setActivatingConnector(connectorsByName["MetaMask"]);
      activate(connectorsByName["MetaMask"]);
    }} >
      <img width="100%" src="https://cdn.iconscout.com/icon/free/png-512/metamask-2728406-2261817.png" />
      <p>MetaMask</p>
    </div>
    <div class="col" style={{background:'whitesmoke', color:'black', padding:'25px', marginLe:'1px', marginRight:'1px', textAlign:'center', cursor:'pointer'}} onClick={() => {
      setActivatingConnector(connectorsByName["BinanceWallet"]);
      activate(connectorsByName["BinanceWallet"]);
    }}>
      <img width="100%" src="https://lh3.googleusercontent.com/rs95LiHzLXNbJdlPYwQaeDaR_-2P9vMLBPwaKWaQ3h9jNU7TOYhEz72y95VidH_hUBqGXeia-X8fLtpE8Zfnvkwa=w128-h128-e365-rj-sc0x00ffffff" />
      <p>Binance Wallet</p>
    </div>
    <div class="col" style={{background:'whitesmoke', color:'black', padding:'25px', marginLe:'1px', marginRight:'1px', textAlign:'center', cursor:'pointer'}} onClick={() => {
      setActivatingConnector(connectorsByName["MetaMask"]);
      activate(connectorsByName["MetaMask"]);
    }}>
      <img width="100" src="https://trustwallet.com/assets/images/media/assets/TWT.png" />
      <p>TrustWallet</p>
    </div>
  </div>
</div>


      </div>    <div class="modal-footer">
        <button style={{width:'100%'}} type="button" class="btn btn-danger" data-bs-dismiss="modal" >Close</button>
      </div>

    </div>
  </div>
</div>


      {(active || error) && (
          <button type="button" class="btn btn-text"
            onClick={() => {
              deactivate();
            }}
            style={{color:'blue', width:'100%', maxWidth:'479px', textAlign:'center'}}
          >
            Disconnect
          </button>
        )}

                    <div className="container" style={{width:'370px', display:'block',margin:'auto', color:'black', marginTop:'3%'}}>
                <br />
 
      {!!error && (
          <h4 style={{ marginTop: "1rem", marginBottom: "0" }}>
            {getErrorMessage(error)}
          </h4>
        )}

        <br />

      
      </div>
                </div>
  <hr />
            </section>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-dialog-centered" >
    <div class="modal-content" style={{backgroundColor:'transparent'}}>

      <div class="modal-body">
        {isresult == true ?       <div>
          <video id="videotoplay" width="420" height="340">
          <source id="videosource" src={videos} type="video/mp4" />

Your browser does not support the animation.
</video>
</div> : <h4 style={{color:'white', textAlign:'center'}}>Waiting...</h4>}


      </div>
      {isresult == true ?        <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div> : null}

    </div>
  </div>
</div>

<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
{/* Same as */}
<ToastContainer />
            </div>
            <br /> <br /> <br />
          </ContentWrapper>
          <Footer />
        </AppWrapper>
        {/* end of app classic landing */}
      </>
    </ThemeProvider>
  );
};
export default TOKENSINGLEGAME;
