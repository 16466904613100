import React from 'react';
import { Icon } from 'react-icons-kit';
import { iosArrowThinRight } from 'react-icons-kit/ionicons/iosArrowThinRight';
import { rgba } from 'polished';

import Container from '../../../common/components/UI/ContainerTwo';
import Image from '../../../common/components/Image';
import illustration from '../../../common/assets/image/agencyDigital/support_illustration.png';
import { data } from '../../../common/data/AgencyDigital';
import support1 from '../../../common/assets/image/agencyDigital/support1.png';
import support2 from '../../../common/assets/image/agencyDigital/support2.png';

import Section, {
  ContentWrapper,
  Content,
  HelpBlock,
  Illustration,
} from './support.style';

const Support = (props) => {
  return (
    <Section>
      <Container>
        <ContentWrapper>
          <Content>
            <h2>Personal Record </h2>
            <p>
              This should give you an idea of your performance
            </p>
              <HelpBlock>
                <div className="icon">
                  <Image src={support1} alt="bnb" />
                </div>
                <div className="content">
                  <h4>
                    Total Bet{' '}
                    <Icon
                      icon={iosArrowThinRight}
                      size={30}
                      style={{ color: rgba('#0F2137', 0.3) }}
                    />
                  </h4>
                  <p> {props.playerBet} </p>
                </div>
              </HelpBlock>
              <HelpBlock>
                <div className="icon">
                  <Image src={support2} alt="bnb" />
                </div>
                <div className="content">
                  <h4>
                    Total Won{' '}
                    <Icon
                      icon={iosArrowThinRight}
                      size={30}
                      style={{ color: rgba('#0F2137', 0.3) }}
                    />
                  </h4>
                  <p> {props.playerWinnings}</p>
                </div>
              </HelpBlock>
          </Content>
          <Illustration>
            <Image src={illustration} alt="" />
          </Illustration>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default Support;
