import React from 'react';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';
import { btc } from 'react-icons-kit/fa/btc';
import { openModal, closeModal } from '@redq/reuse-modal';
import Text from '../../../common/components/Text';
import Image from '../../../common/components/Image';
import Button from '../../../common/components/Button';
import Heading from '../../../common/components/Heading';
import Rating from '../../../common/components/Rating';
import Container from '../../../common/components/UI/Container';
import BannerWrapper, {
  BannerContent,
  RatingInfo,
  BannerImage,
  ButtonGroup,
  VideoGroup,
  VideoWrapper,
  CustomerWrapper,
  ImageWrapper,
} from './banner2.style';

// import { client } from 'common/data/AppModern';

// import microsoft from 'common/assets/image/appModern/envato-icon.png';
import bannerImg from '../../../common/assets/image/appModern/banner2.png';
import videoBanner1 from '../../../common/assets/image/appModern/video-3.png';
// import videoBanner2 from 'common/assets/image/appModern/video-2.png';
import circleBorder from '../../../common/assets/image/appModern/shape.svg';
// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://www.youtube.com/embed/8ME-QAlW6Ww"
      frameBorder="0"
    />
  </VideoWrapper>
);

const Banner = () => {
  // modal handler
  const handleVideoModal = () => {
    openModal({
      config: {
        className: 'video-modal',
        disableDragging: true,
        default: {
          width: 'auto',
          height: 'auto',
          x: 0,
          y: 0,
        },
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Fade up delay={100}>
            <Heading
              as="h1"
              content="Blockchain BNB Lottery"
            />
          </Fade>
          <Fade up delay={200}>
            <Text
              content="Play in the lottery and win the Jackpot!"
            />
          </Fade>
          <VideoGroup>
            <img
              src={videoBanner1}
              alt="Microsoft"
            />
            {/* <img
              src={videoBanner2}
              onClick={handleVideoModal}
              alt="Microsoft"
            /> */}
          </VideoGroup>
        </BannerContent>
        <BannerImage>
          <Fade up delay={100}>
            <Image src={bannerImg} alt="Banner" />
          </Fade>
        </BannerImage>
      </Container>
      <CustomerWrapper>
        <Text content="Disclaimer: This website offers games with an element of chance. By using this site you agree that you are at least 18 years of age and that games with elements of chance are allowed within your jurisdiction. You accept that we are not responsible for any violation of your local laws pertaining to Online gaming and Games of chance." />
        {/* <ImageWrapper>
          {client.map((item) => (
            <Image
              key={`client-key${item.id}`}
              src={item.image}
              alt={item.title}
            />
          ))}
        </ImageWrapper> */}
      </CustomerWrapper>
      <img
        className="bannerBottomShape"
        src={circleBorder}
        alt="Bottom Circle"
      />
    </BannerWrapper>
  );
};

export default Banner;
