import React from 'react';
// import Link from 'next/link';
import Box from '../../../common/components/Box';
import Text from '../../../common/components/Text';
import Image from '../../../common/components/Image';
import Logo from '../../../common/components/UIElements/Logo';
import Heading from '../../../common/components/Heading';
import Container from '../../../common/components/UI/Container';
import FooterArea, {
  WidgetArea,
  MenuArea,
  Menu,
  MenuItem,
  CopyrightText,
} from './footer.style';

import { footer } from '../../../common/data/AppModern';

const Footer = () => {
  const { logo, menu, widgets } = footer;
  const date = new Date();
  const year = date.getFullYear();

  return (
    <FooterArea>
      <Container>
        <WidgetArea>
          {widgets.map((item) => (
            <Box className="col" key={`footer-widget--key${item.id}`}>
              <Image src={item.icon} alt={item.title} />
              <Heading as="h3" content={item.title} />
              <Text content={item.description} />
            </Box>
          ))}
        </WidgetArea>
        {/* End of footer widgets area */}
        <MenuArea>
          <Logo
            className="logo"
            href="/"
            logoSrc={logo}
            title="App Classic"
          />
          <Menu>
            {menu.map((item) => (
              <MenuItem key={`footer-link${item.id}`}>
                <a href={item.link}>
                  {item.text}
                </a>
              </MenuItem>
            ))}
          </Menu>
          <CopyrightText>Copyright {year} By Pool Party Finance</CopyrightText>
        </MenuArea>
        {/* End of footer menu area */}
      </Container>
    </FooterArea>
  );
};

export default Footer;
