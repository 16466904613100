import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Web3ReactProvider
} from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Homepage from './App';
import Lottery from './pages/Lottery';
import ChooseGame from './pages/ChooseGame';
import BNBGAME from './pages/BNB';
import BNBSINGLEGAME from './pages/BNBSINGLEGAME';
import TOKENSINGLEGAME from './pages/TOKENSINGLEGAME';
import TOKENGAME from './pages/TOKENGAME';
import REFERRAL from './pages/REFERRAL';

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
}



function App() {
  return (
    <Router>
    <div>
<Web3ReactProvider getLibrary={getLibrary}>
<Switch>  
           <Route path="/hot/bnb/game/:id">
            <BNBSINGLEGAME />
          </Route>
          <Route path="/hot/token/game/:id">
            <TOKENSINGLEGAME />
          </Route>
          <Route path="/earn">
            <REFERRAL />
          </Route>
          {/*
          <Route path="/token-game/:id">
            <TokenGame />
          </Route>
          <Route path="/tokens/:tokenaddress">
            <TokenBet />
          </Route>
          <Route path="/tokens">
            <TokensJS />
          </Route>
          <Route path="/referral">
            <Referral />
          </Route>
          <Route path="/bnb">
          <MyComponent />
          </Route>*/}
          <Route path="/lottery">
            <Lottery />
          </Route> 
          <Route path="/choose">
            <ChooseGame />
          </Route> 
          <Route path="/hot/bnb">
            <BNBGAME />
          </Route> 
          <Route path="/hot/token/:tokenaddress">
            <TOKENGAME />
          </Route>
          <Route path="/">
            <Homepage />
          </Route>
        </Switch>
    </Web3ReactProvider>
    </div>
    </Router>

  );
}


ReactDOM.render(<App />, document.getElementById("root"));
